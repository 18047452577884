<template>
  <div class="all-images mt-2">
    <b-card-body class="h-100 p-0">
      <div class="px-3 py-2">
          <b-row align-h="center" class="pt-1">
                <b-input-group class="rounded-pill navy-fields">
                  <template #prepend>
                    <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
                      <font-awesome-icon icon="search" />
                    </b-input-group-text>
                  </template>
                  <template #append>
                    <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="search" placeholder="Search Image Name" style="width: 25rem"></b-form-input>
                  <b-col>
                    <b-dropdown class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                      :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                      <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                      <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
                        sg.name }}</b-dropdown-item>
                    </b-dropdown>
                      <p class="mt-0 pt-0 pl-4">Subgroups</p>
                  </b-col>
                  <!-- <b-col>
              <b-dropdown class="my-class ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                text="Pathology" variant="warn">
                  <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                _p.label }}</b-checkbox>
              </b-dropdown>
            </b-col> -->
                  <vue-json-to-csv :json-data="jsonDownload" :csv-title="'DetailedResults'">
                    <b-button class="ml-3 px-3 mt-2 btn  btn-sm btn-blue rounded-pill">
                        Extract CSV
                    </b-button>
                  </vue-json-to-csv>
                </b-input-group>
              </b-row>
        <b-table striped :items="paginatedData" :key="pageNumber">
          <template #cell(name)="data">
            <small>{{ data.item.name }}</small>
          </template>
          <template #cell(metadata)="data">
            <div v-for="(md, index) in data.item.metadata" :key="index">
            <small>  • {{  md[0]  }}:{{  md[1]  }} </small>
          </div>
          </template>
          <template #cell(pathologyCount)="data">
            <small>{{ data.item.pathologyCount }}</small>
          </template>
          <template #cell(maxPathologyPerUser)="data">
            <small>{{ data.item.maxPathologyPerUser }}</small>
          </template>
          <template #cell(result)="data">
            <b-badge pill class="bg-navy color-white py-1 px-2 font-weight-400">
              {{ data.item.result }}
            </b-badge>
          </template>
          <template #cell(performance)="data">
            <b-badge pill class="py-1 px-2 font-weight-900"
              :class="data.item.performance === 'False Positive' || data.item.performance === 'False Negative' ? 'bg-red color-white' : 'bg-green color-black'">
              {{ data.item.performance }}
            </b-badge>
          </template>
          <template #cell(data)="data">
            <b-button pill class="btn-yellow " @click="openBottomSheet(data.item.data)"><font-awesome-icon icon="info"  /></b-button>
          </template>
        </b-table>
      <div class="text-center" v-if="paginatedData.length === 0">
        <!-- Show icon and message when images are empty -->
        <div class="empty-images mb-5">
          <i class="fa fa-image fa-3x"></i>
              <p class="">No Processed images.</p>
        </div>
      </div>
        <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
      </div>
    </b-card-body>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../../helpers/api-route'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'DetailedResults',
  props: {
    project: {}
  },
  components: {
    VueJsonToCsv
  },
  data () {
    return {
      records: [],
      recordsCache: [],
      imgAiData: [],
      imgData: [],
      proImg: [],
      rejections: [],
      search: '',
      filterChoice: '',
      filterChoicePath: '',
      pageNumber: 0,
      itemsPerPage: 16,
      filterChoicePathArray: {},
      filterChoicePathArray2: [],
      aiMetrics: []
    }
  },
  mounted () {
    // // console.log('get data')
    this.imgAiData = this.images.aiImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
    this.proImg = this._project.projectImages
    this.imgData = this.images.labelledImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
    this.rejections = this._project.rejects
    this.dataToTable()
    // this.calcMetrics()
    this.project.pathologies.forEach(_p => {
      this.filterChoicePathArray[_p.label] = false
    })
  },
  computed: {
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    ...mapState('projectSubmission', {
      _project: (state) => state
    }),
    pathList () {
      var paths = this.project.pathologies
      var _paths = paths.sort(this.compareLabel)
      return _paths
    },
    jsonDownload () {
      var _temp = []
      this.reducedImages.forEach(_r => {
        var __temp = {}
        __temp.Name = _r.name
        __temp.ImageURL = _r.data.url
        if (_r.metadata && _r.metadata.length > 0) {
          _r.metadata.forEach(md => {
            __temp[md[0]] = md[1]
          })
        }
        __temp.PathologyCount = _r.pathologyCount
        __temp.MaxPathologiesPerUser = _r.maxPathologyPerUser
        __temp.Performance = _r.performance
        _temp.push(__temp)
      })

      return _temp
    },
    filterChange () {
      return this.filterChoicePathArray2
    },
    reducedImages () {
      this.filterChoicePathArray2.forEach(_key => {
        // console.log('key', _key)
      })
      var _images = this.records
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => this.hasGroup(img, this.filterChoice))
      }
      if (this.search !== '') {
        _images = _images.filter(img => this.inSearch(img, this.search))
      }
      return _images
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.reducedImages.slice(start, end)
    },
    maxPages () {
      return (this.reducedImages.length / this.itemsPerPage >> 0) + 1
    },
    getAi () {
      return this.images.aiLoaded
    },
    imagesLoaded () {
      return this.images.loaded
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    projectImagesLoaded () {
      return !this._project.loadingProjectImages
    },
    rejectWatch () {
      return !this._project.loadingRejects
    }
  },
  watch: {
    filterChange (_new) {
      // console.log('change', _new)
    },
    getAi (_new) {
      if (_new) {
        this.imgAiData = this.images.aiImages
        this.dataToTable()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.imgData = this.images.labelledImages
        this.dataToTable()
      }
    }
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages', 'downloadLabels', 'getBatches']),
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    compareLabel (a, b) {
      return a.label.localeCompare(b.label)
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    dataToTable () {
      // // console.log(this.imgData)
      if (this.getAi && this.imagesLoaded) {
        this.imgData.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.project._id)) {
            const _thisImage = this.proImg.find(_record => _record._id === img.image._id)
            const _moderated = img.users.filter(_record => _record.projectRole.find(__record => __record.project === this.projectId && __record.role === 'Moderator'))
            let _users = this.readers.map(_user => _user._id)
            if (_moderated.length > 0) {
              _users = this.moderators.map(_user => _user._id)
            }

            var mpu = 0
            // var pathCount = 0
            var _pathologies = []
            img.pathologies.filter(_record => _users.indexOf(_record.created_by) >= 0).forEach(p => {
              var runningPath = 0
              Object.keys(p).forEach(pkey => {
                if (pkey !== 'created_by') {
                  runningPath++
                  _pathologies.push(p[pkey].name)
                }
              })
              // pathCount += runningPath
              mpu = mpu < runningPath ? runningPath : mpu
            })
            if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
              img.otherImages.forEach(_item => {
                _item.pathologies.filter(_record => _users.indexOf(_record.created_by) >= 0).forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _pathologies.push(sub[_k].name)
                    }
                  })
                })
              })
            }
            var _tags = []
            // console.log(img)
            img.tags.forEach(sub => {
              var keys = Object.keys(sub)
              keys.forEach(_k => {
                if (_k !== 'created_by') {
                  _tags.push(sub[_k])
                }
              })
            })
            if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
              img.otherImages.forEach(_img => {
                _img.tags.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _tags.push(sub[_k])
                    }
                  })
                })
              })
            }
            var _diagnoses = []
            // console.log(img)
            img.diagnoses.forEach(sub => {
              var keys = Object.keys(sub)
              keys.forEach(_k => {
                if (_k !== 'created_by') {
                  // console.log('_k ', _k)
                  // console.log('_k ', sub[_k])
                  _diagnoses.push(sub[_k])
                }
              })
            })
            if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
              img.otherImages.forEach(_img => {
                _img.diagnoses.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _diagnoses.push(sub[_k])
                    }
                  })
                })
              })
            }
            // // console.log(this.imgAiData)
            var aiImg
            if (img.image && img.image.image_set && img.image.image_set.name) {
              aiImg = this.imgAiData.find(i => img.image.image_set.name === i.image.image_set.name && img.image.image_set.sequence === i.image.image_set.sequence)
              // console.log(aiImg)
            } else {
              aiImg = this.imgAiData.find(i => img.image._id === i.image._id)
              // console.log(aiImg)
            }
            // // console.log('ai', aiImg)
            if (aiImg) {
              // // console.log(aiImg)
              /*
              aiImg.pathologies.forEach(p => {
                Object.keys(p).forEach(pkey => {
                  if (pkey !== 'created_by') {
                    _pathologies.push(p[pkey].name)
                  }
                })
                if (aiImg.otherImages && aiImg.otherImages.length > 0 && aiImg.image.image_set && aiImg.image.image_set.name) {
                  aiImg.otherImages.forEach(_item => {
                    _item.pathologies.forEach(sub => {
                      var keys = Object.keys(sub)
                      keys.forEach(_k => {
                        if (_k !== 'created_by') {
                          _pathologies.push(sub[_k].name)
                        }
                      })
                    })
                  })
                }
              })
              // // console.log('abc', pathCount)
              // // console.log('ai', aiCount)
              var acc
              if (img.image.accuracy.filter(a => a.project === this.project._id).length > 0) {
                acc = img.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value
              } else {
                acc = 0
              }
              var _acc = []
              if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
                img.otherImages.forEach(_img => {
                  _acc.push(_img.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value)
                })
              }
              // console.log('acc', acc)
              _acc.push(acc)
              // console.log('all acc', _acc)
              _acc = _acc.filter(a => a !== 0)
              if (_acc.length > 0) {
                // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
                acc = _acc.reduce((a, b) => a + b) / _acc.length
              } else {
                acc = 0
              }
              */

              var tempRecord = {}
              tempRecord.name = img.image.image_set && img.image.image_set.name ? img.image.image_set.name : img.image.image.name
              tempRecord.metadata = []
              if (img.image.metadata[0]) {
                img.image.metadata[0].values.forEach(md => {
                  tempRecord.metadata.push([md.name, md.value])
                })
              } else {
                tempRecord.metadata.push(['', 'No Meta Data'])
              }
              tempRecord.referenceStandard = mpu

              const _aiAccuracy = _thisImage.ai_accuracy.find(_record => _record.project === this.projectId)
              const _positive = _aiAccuracy && _aiAccuracy.value ? _aiAccuracy.value > 0.00000001 : false
              var aiCount = 0
              Object.keys(aiImg.pathologies[0]).forEach(aiP => {
                if (aiP !== 'created_by' && aiImg.pathologies[0][aiP].name !== 'heart' && aiImg.pathologies[0][aiP].name !== 'apchest') {
                  if (aiImg.pathologies[0][aiP].confidence >= 0.8) {
                    aiCount++
                  }
                }
              })

              if (aiCount === 0 && mpu === 0) {
                tempRecord.performance = 'True Negative'
              } else {
                if (_positive) {
                  tempRecord.performance = 'True Positive'
                } else {
                  if ((aiCount + 1) >= mpu) {
                    tempRecord.performance = 'False Positive'
                  } else {
                    tempRecord.performance = 'False Negative'
                  }
                }
              }

              tempRecord.AI_PathologyCount = aiCount

              // tempRecord.performance = this.confusionMatrix(img.image._id)
              tempRecord.data = {
                id: img.image._id,
                url: apiRoute() + '/image/' + img.image._id,
                details: {
                  created: img.image.created_at,
                  format: img.image.image.contentType,
                  imgName: img.image.image_set && img.image.image_set.name ? img.image.image_set.name : img.image.image.name,
                  meta: img.image.metadata,
                  iou: img.image.accuracy.filter(a => a.project === this.project._id).length > 0 ? img.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value : 0,
                  aiIov: img.image.ai_accuracy.filter(a => a.project === this.project._id).length > 0 ? img.image.ai_accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value : null,
                  pathologies: new Set(_pathologies),
                  tags: new Set(_tags),
                  diagnoses: new Set(_diagnoses),
                  image_set: img.image.image_set && img.image.image_set.name ? img.image.image_set : null,
                  otherImages: img.otherImages
                }
              }
              this.records.push(tempRecord)
            }
          }
        })
        this.recordsCache = this.records
      }
    },
    hasPath (img, filter) {
      var temp = false
      if (this.filterChoicePathArray2.length === 0) {
        temp = true
      }
      Object.keys(filter).forEach(_ch => {
        if (_ch === 'Other' && this.filterChoicePathArray[_ch]) {
          img.data.details.pathologies.forEach(v => {
            if (!this.project.pathologies.find(p => p.label === v)) {
              temp = true
            }
          })
          // // console.log(img.details.pathologies.values())
          // // console.log(this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1) && this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1
          // && this.project.pathologies.filter(p => img.details.pathologies.has(p))) {p => !this.project.pathologies.includes(p)
        } else if (_ch !== 'Other' && this.filterChoicePathArray[_ch] && img.data.details.pathologies.has(_ch)) {
          temp = true
        }
      })
      return temp
    },
    addPath (val) {
      if (this.filterChoicePathArray2.includes(val)) {
        this.filterChoicePathArray2 = this.filterChoicePathArray2.filter(p => p !== val)
      } else {
        this.filterChoicePathArray2.push(val)
      }
    },
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.data.details.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // console.log(img.data.details)
            if (img.data.details.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.data.details.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.data.details.meta && img.data.details.meta[0] && img.data.details.meta[0] && img.data.details.meta[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.data.details.meta[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    inSearch  (img, val) {
      // console.log(img)
      if (img.data.details.image_set && img.data.details.image_set.name) {
        return img.data.details.image_set.name.includes(val) || img.data.details.image_set.sequence.includes(val)
      } else {
        return img.data.details.imgName.includes(val)
      }
    },
    confusionMatrix (imgID) {
      var img = this.imgData.find(i => i.image._id === imgID)
      var aiImg
      if (img.image && img.image.image_set && img.image.image_set.name) {
        aiImg = this.imgAiData.find(i => img.image.image_set.name === i.image.image_set.name && img.image.image_set.sequence === i.image.image_set.sequence)
        // console.log(aiImg)
      } else {
        aiImg = this.imgAiData.find(i => img.image._id === i.image._id)
        // console.log(aiImg)
      }
      const _moderated = img.users.filter(_record => _record.projectRole.find(__record => __record.project === this.project._id && __record.role === 'Moderator'))
      let _users = this.readers.map(_user => _user._id)
      if (_moderated.length > 0) {
        _users = this.moderators.map(_user => _user._id)
      }
      if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
        var mpu = 0
        // var pathCount = 0
        img.pathologies.filter(_record => _users.indexOf(_record.created_by) >= 0).forEach(p => {
          var runningPath = 0
          Object.keys(p).forEach(pkey => {
            if (pkey !== 'created_by') {
              runningPath++
            }
          })
          // pathCount += runningPath
          mpu = mpu < runningPath ? runningPath : mpu
        })
        const _aiAccuracy = img.image.ai_accuracy.find(_record => _record.project === this.project._id)
        const _positive = _aiAccuracy && _aiAccuracy.value ? _aiAccuracy.value > 0.01 : false
        var aiCount = 0
        // console.log(aiImg)
        Object.keys(aiImg.pathologies[0]).forEach(aiP => {
          if (aiP !== 'created_by' && aiImg.pathologies[0][aiP].name !== 'heart' && aiImg.pathologies[0][aiP].name !== 'apchest') {
            aiCount++
          }
        })
        if (aiCount === 0 && mpu === 0) {
          return 'True Negative'
        } else {
          if (_positive) {
            return 'True Positive'
          } else {
            if (aiCount >= mpu) {
              return 'False Positive'
            } else {
              return 'False Negative'
            }
          }
        }
      }
    },
    openBottomSheet (image) {
      // // console.log('image', image)
      image.analyzed = true
      if (this.selectedImage === image) {
        // Deselect the image if it's already selected
        this.selectedImage = null
      } else {
        // Select the image
        this.selectedImage = image
      }
      this.$emit('open-bottom-sheet', image)
    }
  }
}
</script>
<style>
tr{
  /* border-top: 1px red; */
}
</style>
<style scoped>
  .my-class /deep/ .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }

.empty-images {
  color: #ccc;
  margin-top: 50px;
}

</style>
